<template>
  <div id="image-list">

    <!-- app drawer -->
    <add-new-image
      v-model="isAddNewImageSidebarActive"
      @refetch-data="isAddNewImageSidebarActive = false; loadImages()"
    ></add-new-image>

    <change-image
      v-model="isChangeImageSidebarActive"
      :image="selectedRow"
      @refetch-data="isChangeImageSidebarActive = false; loadImages()"
    ></change-image>

    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            Images Klara 365
          </v-card-title>
          <v-row class="px-2 ma-0">
            <!-- role filter -->
            <v-col
              cols="12"
              sm="9"
            >
              <!-- search -->
              <v-text-field
                v-model="searchQuery"
                placeholder="Search"
                outlined
                hide-details
                dense
                class="mb-4"
              >
              </v-text-field>

            </v-col>


            <v-col
              cols="12"
              sm="3"
              class="text-right"
            >

                <v-btn
                  color="success"
                  class="mb-4"
                  @click.stop="isAddNewImageSidebarActive = !isAddNewImageSidebarActive"
                >
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                  <span>Add New Image</span>
                </v-btn>


            </v-col>
          </v-row>

          <!-- table -->
          <v-data-table
            ref="refImagesTable"
            v-model="selectedRow"
            :headers="tableColumns"
            :items="imagesListTable"
            :options.sync="options"
            :server-items-length="totalImagesListTable"
            :loading="loading"
            :footer-props="{'items-per-page-options':[25, 50, 100]}"
            @click:row="rowClick"
            single-select
            :item-class="rowClass"
            dense
            item-key="id"
            group-by="week_number"
          >

          <template v-slot:group.header="{items, isOpen, toggle}">
            <th colspan="6">
              <v-icon @click="toggle"
                >{{ isOpen ? icons.mdiMinus : icons.mdiPlus }}
              </v-icon>
              Week #{{ items[0].week_number }}
            </th>
          </template>

            <template #[`item.image`]="{item}">
              <div class="pa-1">
                <v-img :src="item.image" contain width="50" height="50"></v-img>
              </div>
            </template>

            <template #[`item.image_date`]="{item}">
              <div class="">
                {{ moment(item.image_date).format("D.M.Y") }}
              </div>
            </template>

            <template #[`item.weekly_highlight`]="{item}">
              <v-icon
                color="success"
                size="22"
                v-if="item.weekly_highlight > 0"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </template>

            <template #[`item.monthly_highlight`]="{item}">
              <v-icon
                color="success"
                size="22"
                v-if="item.monthly_highlight > 0"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </template>

            <template #[`item.yearly_highlight`]="{item}">
              <v-icon
                color="success"
                size="22"
                v-if="item.yearly_highlight > 0"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <image-detail @open-change-image="isChangeImageSidebarActive = !isChangeImageSidebarActive" @refetch-data="loadImages()" :image="selectedRow"></image-detail>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import {
  mdiPlus,
  mdiCheck,
  mdiPencilOutline,
  mdiMinus,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import moment from 'moment'

// sidebar
import AddNewImage from './AddNewImage.vue'
import ChangeImage from './ChangeImage.vue'
import ImageDetail from './ImageDetail.vue'
import imagesStoreModule from './imagesStoreModule'

// import useUsersList from './useUsersList'

export default {
  components: {
    AddNewImage,
    ImageDetail,
    ChangeImage
  },
  setup() {
    const LIFETIME_ALBUM_STORE_MODULE_NAME = 'app-lifetime-album'

    // Register module
    if (!store.hasModule(LIFETIME_ALBUM_STORE_MODULE_NAME)) store.registerModule(LIFETIME_ALBUM_STORE_MODULE_NAME, imagesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LIFETIME_ALBUM_STORE_MODULE_NAME)) store.unregisterModule(LIFETIME_ALBUM_STORE_MODULE_NAME)
    })

    const loadImages = () => {
      store
        .dispatch('app-lifetime-album/fetchImages', {
          q: searchQuery.value,
          options: options.value
        })
        .then(response => {
          const { filteredData, total } = response.data

          imagesListTable.value = filteredData
          totalImagesListTable.value = total

          // remove loading state
          loading.value = false
          if (selectedRow.value.length) {
            currentIndex.value = imagesListTable.value.map(function(x) {return x.id; }).indexOf(selectedRow.value[0].id);
          }

          selectedRow.value = []
          setTimeout(function () {
            document.getElementsByClassName('image_table_row')[currentIndex.value].click()
          },100);

        })
        .catch(error => {
          console.log(error)
        })
    }

    const searchQuery = ref('')
    const selectedRow = ref([])
    const refImagesTable = ref();
    const imagesListTable = ref([])
    const currentIndex = ref(0)

    const tableColumns = [
      { text: 'Image', value: 'image' },
      { text: 'Image date', value: 'image_date' },
      { text: 'Title', value: 'title' },
      { text: 'Weekly highlight', value: 'weekly_highlight', align: "center" },
      { text: 'Monthly highlight', value: 'monthly_highlight', align: "center" },
      { text: 'Yearly highlight', value: 'yearly_highlight', align: "center" },
    ]

    const loading = ref(false)
    const totalImagesListTable = ref(0)
    const options = ref({
      sortBy: ['image_date'],
      sortDesc: [true],
      itemsPerPage: 25
    })

    const isAddNewImageSidebarActive = ref(false)
    const isChangeImageSidebarActive = ref(false)

    const rowClick = (item, row) => {
      row.select();
    }

    const rowClass = (item) => {
      return "image_table_row"
    }

    watch([searchQuery, options], () => {
      loading.value = true
      loadImages()
    })

    window.addEventListener('keydown', (e) => {
      if (selectedRow.value.length) {
        let _index = imagesListTable.value.map(function(x) {return x.id; }).indexOf(selectedRow.value[0].id);

        if (e.key == 'ArrowDown') {
          if (typeof document.getElementsByClassName('image_table_row')[_index+1] !== undefined) {
            document.getElementsByClassName('image_table_row')[_index+1].click()
          }
        }
        if (e.key == 'ArrowUp') {
          if (typeof document.getElementsByClassName('image_table_row')[_index-1] !== undefined) {
            document.getElementsByClassName('image_table_row')[_index-1].click()
          }
        }
      }
    });

    const successMsg = ref(true)


    return {
      isAddNewImageSidebarActive,
      isChangeImageSidebarActive,
      loadImages,
      searchQuery,
      tableColumns,
      imagesListTable,
      options,
      totalImagesListTable,
      loading,
      rowClick,
      selectedRow,
      refImagesTable,
      rowClass,
      moment,

      // icons
      icons: {
        mdiPlus,
        mdiCheck,
        mdiPencilOutline,
        mdiMinus
      },
    }
  },
}
</script>

<style lang="scss">
  tr.v-data-table__selected {
    background: var(--v-warning-base) !important;
    td {

    }
  }
</style>
