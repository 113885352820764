import axios from '@axios'
import themeConfig from '@themeConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchImages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/lifetime-album', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addImage(ctx, imageData) {
      return new Promise((resolve, reject) => {

        let formData = new FormData();
        for (const [key, value] of Object.entries(imageData)) {
          formData.append(key,value)
        }

        axios
          .post(themeConfig.api_prefix + '/lifetime-album', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    changeImage(ctx, params) {
      return new Promise((resolve, reject) => {

        let formData = new FormData();
        for (const [key, value] of Object.entries(params)) {
          formData.append(key,value)
        }

        axios
          .post(themeConfig.api_prefix + `/lifetime-album/${params.id}/update-image`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateImage(ctx, imageData) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/lifetime-album/${imageData.id}/edit`,  imageData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    setHighlight(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/lifetime-album/${params.id}/set-highlight`,  {"type": params.type, "value": params.value})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
