<template>
  <v-navigation-drawer
    :value="isChangeImageSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-image-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Change Image</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-image-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-file-input
            show-size
            accept="image/*"
            label="Image"
            hide-details="auto"
            class="mb-6"
            :prepend-icon="icons.mdiCameraOutline"
            @change="imagePreview"
            @input="errors.length = 0"
            :error-messages="errors.file"
          ></v-file-input>

          <v-img contain height="300" v-show="imageUrl" class="mb-6" :src="imageUrl"/>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Save
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetImageData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCalendar, mdiCameraOutline } from '@mdi/js'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import moment from 'moment'

export default {
  model: {
    prop: 'isChangeImageSidebarActive',
    event: 'update:is-change-image-sidebar-active',
  },
  props: {
    isChangeImageSidebarActive: {
      type: Boolean,
      required: true,
    },
    image: {
      type: Array,
      required: true,
    }
  },
  setup(props, { emit }) {

    const imageUrl = ref(null)

    const valid = ref(false)
    const form = ref(null)
    const errors = ref([])

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const newImage = ref({})
    const resetImageData = () => {
      newImage.value = {}
      resetForm()
      emit('update:is-change-image-sidebar-active', false)
    }

    const imageData = ref(JSON.parse(JSON.stringify(props.image)))

    const imagePreview = (image) => {
      if (image) {
          newImage.value = image
          imageUrl.value = URL.createObjectURL(image)
      } else {
          imageUrl.value = null
      }
    }

    const onSubmit = () => {

      let params = {
        file: newImage.value,
        id: imageData.value.id
      }

      store.dispatch('app-lifetime-album/changeImage', params).then((response) => {
        emit('refetch-data')
        resetImageData()
        store.dispatch("addNotification",{message:"Image was successfully uploaded.",type: "success"});
      }).catch((error) => {
        errors.value = error.response.data.error
      })

    }

    watch(() => props.image, (value, prevValue) => {
        if (value.length) {
            imageData.value = JSON.parse(JSON.stringify(value[0]))
        }
    })

    return {
      resetImageData,
      form,
      onSubmit,
      imageUrl,
      newImage,
      valid,
      validate,
      errors,
      imagePreview,

      // validation
      validators: { required},
      icons: {
        mdiClose,
        mdiCalendar,
        mdiCameraOutline
      },
    }
  },
}
</script>
