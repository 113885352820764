<template>
  <v-navigation-drawer
    :value="isAddNewImageSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-image-sidebar-active', val)"
  >
    <v-card style="padding-bottom: 50px;height: 100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Image</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-image-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-file-input
            show-size
            accept="image/*"
            label="Image"
            hide-details="auto"
            class="mb-6"
            :prepend-icon="icons.mdiCameraOutline"
            @change="imagePreview"
            @input="errors.file = []"
            :error-messages="errors.file"
          ></v-file-input>

          <v-img contain height="300" v-show="imageData.url" class="mb-6" :src="imageData.url"/>

          <v-menu
            v-model="isImageDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="imageData.date"
                label="Image date"
                :prepend-icon="icons.mdiCalendar"
                :rules="[validators.required]"
                readonly
                hide-details="auto"
                v-bind="attrs"
                v-on="on"
                class="mb-6"
                :error-messages="errors.date"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="imageData.date"
              @input="isImageDateMenuOpen = false; errors.date = []"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="imageData.title"
            outlined
            dense
            :rules="[validators.required]"
            label="Image Title"
            hide-details="auto"
            class="mb-6"
            :error-messages="errors.title"
            @input="errors.title = []"
          ></v-text-field>

          <v-textarea
            v-model="imageData.description"
            outlined
            dense
            label="Image Description"
            hint="Optional"
            persistent-hint
            hide-details="auto"
            class="mb-6"
          ></v-textarea>



          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetImageData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCalendar, mdiCameraOutline } from '@mdi/js'
import store from '@/store'
import { ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import moment from 'moment'

export default {
  model: {
    prop: 'isAddNewImageSidebarActive',
    event: 'update:is-add-new-image-sidebar-active',
  },
  props: {
    isAddNewImageSidebarActive: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, { emit }) {

    const blankImageData = {
      date: moment().format("YYYY-MM-DD"),
      url: null,
      title: '',
      description: '',
    }

    const valid = ref(false)
    const form = ref(null)
    const errors = ref([])
    const isImageDateMenuOpen = ref(false)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const imageData = ref(JSON.parse(JSON.stringify(blankImageData)))
    const resetImageData = () => {
      imageData.value = JSON.parse(JSON.stringify(blankImageData))
      resetForm()
      emit('update:is-add-new-image-sidebar-active', false)
    }

    const imagePreview = (image) => {
      if (image) {
          imageData.value.file = image
          imageData.value.url = URL.createObjectURL(image)
      } else {
          imageData.value.url = null
      }
    }

    const onSubmit = () => {

      if (valid.value) {
        store.dispatch('app-lifetime-album/addImage', imageData.value).then(() => {
          emit('refetch-data')
          resetImageData()
          store.dispatch("addNotification",{message:"Image was successfully uploaded.",type: "success"});
        }).catch((error) => {
          errors.value = error.response.data.error
        })

      } else {
        validate();
      }

    }

    return {
      resetImageData,
      form,
      onSubmit,
      imageData,
      valid,
      validate,
      errors,
      isImageDateMenuOpen,
      imagePreview,

      // validation
      validators: { required},
      icons: {
        mdiClose,
        mdiCalendar,
        mdiCameraOutline
      },
    }
  },
}
</script>
