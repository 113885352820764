<template>
  <div class="sticky_wrapper">

  <v-card>
    <v-card-title class="justify-center flex-column">

      <v-hover
        v-slot="{ hover }"
      >
        <div style="position:relative; max-width: 100%">
          <v-img :class="{ 'on-hover': hover }" contain height="300" :src="imageData.image"/>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#f2f1f1"
            >
              <v-btn fab @click="bigImage = !bigImage">
                <v-icon>{{ icons.mdiMagnify }}</v-icon>
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="error" fab @click="$emit('open-change-image')">
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </v-overlay>
          </v-fade-transition>
        </div>
      </v-hover>

      <v-overlay :value="bigImage">
        <v-img contain height="80vh" @click="bigImage = false" :src="imageData.image"/>
      </v-overlay>

      <v-edit-dialog
        :return-value.sync="imageData.title"
        large
        persistent
        @save="saveImageData"
      >
        <v-chip
          label
          :class="`v-chip-light-bg text-sm primary--text font-weight-semibold mt-6`"
          id="image-title"
        >
          {{ imageData.title }}
          <v-icon size="12" class="ml-3" color="primary">
            {{ icons.mdiPencilOutline }}
          </v-icon>
        </v-chip>
        <template v-slot:input>
          <div class="mt-4 title">
            Edit image title
          </div>
          <v-text-field
            v-model="imageData.title"
            label="Image Title"
            single-line
            autofocus
          ></v-text-field>
        </template>
      </v-edit-dialog>

    </v-card-title>

    <v-card-text class="d-flex justify-center mt-2 pe-sm-0">
      <div class="d-flex align-center me-8 mb-4">
        <v-avatar
          size="40"
          rounded
          color="secondary"
          :class="imageData.weekly_highlight == 1 ? 'success--text v-avatar-light-bg me-3' : 'error--text v-avatar-light-bg me-3'"
          @click="setHighlight('weekly')"
        >
          <v-icon
            :color="imageData.weekly_highlight == 1 ? 'success' : 'error'"
            size="22"
          >
            {{ imageData.weekly_highlight == 1 ? icons.mdiCheck : icons.mdiClose }}
          </v-icon>
        </v-avatar>

        <div>
          <span>Weekly Highlight</span>
        </div>
      </div>

      <div class="d-flex align-center me-8 mb-4">
        <v-avatar
          size="40"
          rounded
          color="secondary"
          :class="imageData.monthly_highlight == 1 ? 'success--text v-avatar-light-bg me-3' : 'error--text v-avatar-light-bg me-3'"
          @click="setHighlight('monthly')"
        >
          <v-icon
            :color="imageData.monthly_highlight == 1 ? 'success' : 'error'"
            size="22"
          >
            {{ imageData.monthly_highlight == 1 ? icons.mdiCheck : icons.mdiClose }}
          </v-icon>
        </v-avatar>

        <div>
          <span>Monthly Highlight</span>
        </div>
      </div>

      <div class="d-flex align-center me-8 mb-4">
        <v-avatar
          size="40"
          rounded
          color="secondary"
          :class="imageData.yearly_highlight == 1 ? 'success--text v-avatar-light-bg me-3' : 'error--text v-avatar-light-bg me-3'"
          @click="setHighlight('yearly')"
        >
          <v-icon
            :color="imageData.yearly_highlight == 1 ? 'success' : 'error'"
            size="22"
          >
            {{ imageData.yearly_highlight == 1 ? icons.mdiCheck : icons.mdiClose }}
          </v-icon>
        </v-avatar>

        <div>
          <span>Yearly Highlight</span>
        </div>
      </div>
    </v-card-text>

    <v-card-text>
      <h2 class="text-xl font-weight-semibold mb-2">
        Details
      </h2>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium me-2">Date:</span>
          <v-menu
            v-model="isImageDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-hover
                v-slot="{ hover }"
              >
                <div class="">
                  <span class="text--secondary" v-bind="attrs" v-on="on" v-model="imageData.image_date" style="cursor:pointer">{{ moment(imageData.image_date).format("D.M.Y") }}</span>
                  <v-icon size="12" class="ml-2" v-if="hover">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </div>
              </v-hover>
            </template>

            <v-date-picker
              v-model="imageData.image_date"
              @input="isImageDateMenuOpen = false; saveImageData()"
              :max="moment().format('Y-M-D')"
            ></v-date-picker>
          </v-menu>

        </v-list-item>

        <v-list-item
          dense
          class="px-0 mb-n2"
        >
          <span class="font-weight-medium text-no-wrap me-2">Description:</span>
          <v-edit-dialog
            :return-value.sync="imageData.description"
            large
            persistent
            @save="saveImageData"
          >
            <v-hover
              v-slot="{ hover }"
            >
              <div class="">
                <span class="text--secondary">{{ imageData.description }}</span>
                <v-icon size="12" class="ml-2" v-if="hover || !imageData.description">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </div>
            </v-hover>

            <template v-slot:input>
              <div class="mt-4 title">
                Edit image description
              </div>
              <v-textarea
                v-model="imageData.description"
                autofocus
              ></v-textarea>
            </template>
          </v-edit-dialog>
        </v-list-item>

      </v-list>
    </v-card-text>

  </v-card>

  </div>
</template>

<script>
import { mdiClose, mdiCheck, mdiPencilOutline, mdiMagnify } from '@mdi/js'
import { watch, ref } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import moment from 'moment'
import store from '@/store'

export default {
  props: {
    image: {
      type: Array,
      required: true,
    }
  },
  setup(props, { emit }) {

    const imageData = ref(JSON.parse(JSON.stringify(props.image)))

    const isImageDateMenuOpen = ref(false)
    const bigImage = ref(false)

    const showEditTitle = ref(false)
    const saveImageData = () => {
      store.dispatch('app-lifetime-album/updateImage', imageData.value).then(() => {
        emit('refetch-data')
        store.dispatch("addNotification",{message:"Image was successfully updated.",type: "success"});
      }).catch((error) => {
        store.dispatch("addNotification",{message:"Image was not updated. " + error.response.data.error.message ,type: "error"});
        // errors.value = error.response.data.error
      })
    }

    const setHighlight = (type) => {

      let params = {
        id: imageData.value.id,
        type: type,
        value: imageData.value[type + "_highlight"] == 1 ? 0 : 1
      }
      store.dispatch('app-lifetime-album/setHighlight', params).then(() => {
        emit('refetch-data')
        store.dispatch("addNotification",{message:"Highlight was set.",type: "success"});
      }).catch((error) => {
        store.dispatch("addNotification",{message:"There was a problem with setting the highlight.",type: "error"});
      })
    }

    watch(() => props.image, (value, prevValue) => {
      if (value.length) {
       imageData.value = JSON.parse(JSON.stringify(value[0]))
      }
    })

    return {
      imageData,
      moment,
      saveImageData,
      isImageDateMenuOpen,
      bigImage,
      setHighlight,

      icons: {
        mdiClose,
        mdiCheck,
        mdiPencilOutline,
        mdiMagnify
      },
    }
  },
}
</script>

<style lang="scss">
  .sticky_wrapper {
    position: sticky; top: 150px;
  }
  .v-avatar {
    cursor: pointer;
  }
  #image-title {
    cursor: pointer;
    .v-icon {
      display: none;
    }
    &:hover {
      .v-icon {
        display: inline-block;
      }
    }
  }
</style>
