var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"image-list"}},[_c('add-new-image',{on:{"refetch-data":function($event){_vm.isAddNewImageSidebarActive = false; _vm.loadImages()}},model:{value:(_vm.isAddNewImageSidebarActive),callback:function ($$v) {_vm.isAddNewImageSidebarActive=$$v},expression:"isAddNewImageSidebarActive"}}),_c('change-image',{attrs:{"image":_vm.selectedRow},on:{"refetch-data":function($event){_vm.isChangeImageSidebarActive = false; _vm.loadImages()}},model:{value:(_vm.isChangeImageSidebarActive),callback:function ($$v) {_vm.isChangeImageSidebarActive=$$v},expression:"isChangeImageSidebarActive"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Images Klara 365 ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-text-field',{staticClass:"mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewImageSidebarActive = !_vm.isAddNewImageSidebarActive}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Image")])],1)],1)],1),_c('v-data-table',{ref:"refImagesTable",attrs:{"headers":_vm.tableColumns,"items":_vm.imagesListTable,"options":_vm.options,"server-items-length":_vm.totalImagesListTable,"loading":_vm.loading,"footer-props":{'items-per-page-options':[25, 50, 100]},"single-select":"","item-class":_vm.rowClass,"dense":"","item-key":"id","group-by":"week_number"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"6"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? _vm.icons.mdiMinus : _vm.icons.mdiPlus)+" ")]),_vm._v(" Week #"+_vm._s(items[0].week_number)+" ")],1)]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1"},[_c('v-img',{attrs:{"src":item.image,"contain":"","width":"50","height":"50"}})],1)]}},{key:"item.image_date",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.moment(item.image_date).format("D.M.Y"))+" ")])]}},{key:"item.weekly_highlight",fn:function(ref){
var item = ref.item;
return [(item.weekly_highlight > 0)?_c('v-icon',{attrs:{"color":"success","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()]}},{key:"item.monthly_highlight",fn:function(ref){
var item = ref.item;
return [(item.monthly_highlight > 0)?_c('v-icon',{attrs:{"color":"success","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()]}},{key:"item.yearly_highlight",fn:function(ref){
var item = ref.item;
return [(item.yearly_highlight > 0)?_c('v-icon',{attrs:{"color":"success","size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheck)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('image-detail',{attrs:{"image":_vm.selectedRow},on:{"open-change-image":function($event){_vm.isChangeImageSidebarActive = !_vm.isChangeImageSidebarActive},"refetch-data":function($event){return _vm.loadImages()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }